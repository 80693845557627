@media only screen and (max-width: 1450px) {

   

    .changed-row{
        background: black;
        position: fixed;
        display: inline;
        width: 85%!important;
        height: 51px!important;
        bottom: 0px!important;
        padding: 1% 1%;
        margin-left:-4%;
    }
    
    .changed-col{
       
    }

    .overlay-row{
        position: fixed;
        background-color:rgba(0, 0, 0, 0.651);
        z-index: 999;
        width: 100%;
       height: 100%;
       top: 0;
       padding:10%;
    }

    .save-changes{
        margin-left: -15%!important;
    }
    
    .changed-col p{
        color: white;
        float: left;
        width: 80%;
        padding-top: 0.2%;
    }
    
    .changed-button{
        float: right;
        width: 10%;
        margin-right: 3.5%;
      margin-top: -0.4%!important;
    }
    .participant {
        border-top-left-radius: 40px!important;
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        height: 48em;
        margin-bottom: 5%;
    }
    
    .recept-kok img {
        width: 80%;
        margin-left: 10%;
    }
    .recept-kok p {
        text-align: left;
        margin-top: 7%;
    }
    
    .logo-company img {
        width: 50%;
        object-fit: contain;
        margin-top: -86%;
        margin-left: 25%!important;
    }

    .map-col{
        margin-top: 5%;
        margin-bottom: 40%;
        width: 80%;
        margin-left: 4%!important;
    }
}
   