@media only screen and (max-width: 800px){

    .desktop-menu .menu-section a {
        padding: 1% 3%;
        color: black;
        margin-right: 1%;
        margin-top: 6%;
        font-family: 'Gaspar-bold';
    }
    .desktop-menu .logo-section img {
        width: 31%;
        margin-left: 60%;
        padding: 4%;
        margin-top: 2%;
    }
    .desktop-menu .sub-logo-section img {
        width: 25%;
        margin-right: 90%!important;
        padding: 4%;
        margin-top: 8%;
    }
    .usp-image {
        background-image: url(/static/media/Jesse.65221ef9.jpg);
        background-color: #cccccc;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 5%;
    }
    .home-banner-row {
        background-image: url(/static/media/home.08174d23.png);
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 13% 5% 13% 5%;
    }

    .usp-text p {
        margin-left: 22%;
    }
    .menu-title {
        width: 100%;
        float: left;
        padding-top: 2%;
        font-size: 13pt;
    }
    .price {
        width: 100%;
        float: right;
        font-size: 13pt;
        color: orange;
        margin-top: -9%;
        text-align: left;
        margin-bottom: 2%;
    }

    .add-to-cart {
        width: 100%;
        text-align: left;
        color: orange;
        float: right!important;
    }

    .add-to-cart-button {
        background-color: orange;
        color: white;
        border: none!important;
        margin-top: 3%!important;
        width: 100%;
    }

    .menu-col ul {
        list-style: none;
        padding-left: 0%;
        margin-bottom: 115%;
    }

    h3 {
        font-family: 'Messenger-rough', sans-serif;
        font-weight: 600;
        font-size: 14pt;
        color: #3d3934;
    }

    .overlay-colum {
        background-color: white;
        border-radius: 20px!important;
        padding: 5%;
        margin-bottom: 75%;
    }

    .close-button {
        position: absolute;
        margin-top: 5%;
        top: 10px;
        right: 32px;
        font-size: 18pt;
        color: red;
    }

    .product-price-shoppinglist {
        margin-right: 4%;
        margin-left: 3%;
        float: left!important;
        width: 20%;
        margin-top: 2%;
    }
    .pay-bill-button {
        width: 40%;
        background-color: #47B362;
        border: none;
        padding: 2% 3%;
        font-weight: 800;
        color: white;
        text-align: center;
        border-radius: 5px;
        float: right;
    }

    .checkout-detail{
        margin-bottom: 10%;
    }

    .product-title-shoppinglist-checkout {
        margin-right: 4%;
        margin-left: 0%; 
        float: left;
        width: 40%;
        margin-top: 7%;
    }
    .product-price-shoppinglist-checkout {
        margin-right: 4%;
        margin-left: 3%;
        float: right;
        width: 20%;
        margin-top: 7%;
        text-align: right;
    }

    .checkout-shoppinglist li{
        margin-bottom: 10%;
    }

    .pay-bill-button-checkout {
        width: 100%;
        background-color: #47B362;
        border: none;
        padding: 3% 3%;
        font-weight: 800;
        color: white;
        text-align: center;
        border-radius: 5px;
        float: right;
    }

    .firstblock-thankyou, .secondblock-thankyou, .thirdblock-thankyou {
        font-size: 9pt!important;
    }

}