@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;0,800;0,900;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla&display=swap');

@font-face {
    font-family: "Flamante SemiSlab";
    src: url('../assets/fonts/Flamente/Flamante-SemiSlab-Medium-FFP2.ttf');
    font-weight: bold;
}

@font-face {
    font-family: "Photograph Signature";
    src: url('../assets/fonts/Photograph-Signature.ttf');
    font-weight: normal;
}

@font-face {
    font-family: "Gaspar-normal";
    src: url('../assets/fonts/Gaspar-Font/gaspar-regular.ttf');
    font-weight: normal;
}

@font-face {
    font-family: "Gaspar-bold";
    src: url('../assets/fonts/Gaspar-Font/Gaspar_Bold.ttf');
    font-weight: bold;
}

@font-face {
    font-family: "Messenger-rough";
    src: url('../assets/fonts/Messenger-Rough.otf');
    font-weight: bold;
}


html,
body {
    background-color: #ebebeb;
    overflow-x: hidden !important;
}

.usps-row {
    margin-top: 10% !important;
}

.postal-error p {
    color: red;
    margin-top: 5%;
}

h1 {

    font-weight: 900;
    font-size: 22pt;
    color: #3d3934;

}

.table-link {
    margin-left: 5%;
    float: right;
}

.subtotal-css {
    margin-top: 7% !important;
    position: inherit !important;
}

h2 {

    font-weight: 700;
    font-size: 18pt;
    color: #3d3934;
}


h3 {

    font-weight: 600;
    font-size: 17pt;
    color: #3d3934;
}

p {
    font-family: 'Karla', sans-serif !important;
    font-weight: 400;
    font-size: 11pt;
}

label {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.products-table table {
    width: 102.5%;
    margin-left: -1.4%;

}

.products-table table tr {
    padding-top: 2%;
    padding-bottom: 2% !important;

}

.carousel {
    box-shadow: none;
}

.image-slider {
    width: 80%;
    margin-left: 10%;
    margin-top: 25%;
}

.image-slider img {
    width: 50% !important;

}

.products-table .active td {
    background-color: cornflowerblue !important;
    color: white;
}

.products-table table tr th {
    width: 20%;
    padding: 2%;
    background-color: white;

}

.products-table table tr td {
    width: 20%;
    padding: 2%;
    background-color: white;

}

.products-actions {
    width: 100% !important;
    padding: 1%;
    background-color: white;
    margin-bottom: 2%;

}

.products-actions a {
    margin-right: 1%;
    border: solid 1px rgb(105, 104, 104);
    padding: 1%;
    border-radius: 5px;
    color: black;
    float: left;
}

.products-actions a:hover {
    background-color: rgb(99, 96, 96);
    color: white;
    text-decoration: none;
}

.delete-button {
    padding: 1.3%;
    background-color: red;
    border: red solid 1px;
    float: left;
}

.delete-button:hover {
    background-color: rgb(150, 5, 5);
    border: rgb(150, 5, 5) solid 1px;
    float: left;
}

.align-button-right {
    float: right !important;
}

.add-product-colum {
    background-color: white;
    padding: 4% 2%;
    margin-bottom: 3%;

}

.colum-left {

    padding-right: 3%;
}

.add-product-colum input {
    width: 100%;
    padding: 1%;
}

.rdw-editor-wrapper {
    border: solid 0.5px rgba(129, 128, 128, 0.137);
    border-radius: 5px;
}

.editorClassName {
    min-height: 100px;
    padding-left: 3%;
}

.add-product-colum select {
    width: 100%;
    padding: 1.5%;
    margin-bottom: 3%;
}

.right-product-add-colom {
    padding: 7% 4% !important;
}

.right-product-add-colom span {
    color: gray;
}

.add-product-colum input[type="file"] {
    width: 75% !important;
    border: solid 1px black;
    border-radius: 5px;
}

.add-product-colum button {
    float: right;
    padding: 2%;
}

.product-images {
    padding: 0%;
    padding-left: 2%;

}

.product-images img {
    margin-top: 5%;
    width: 100%;
    border-radius: 5px;
}

.nothing-present {
    color: blue;
}

.input-price {
    font-size: 12pt;
}

.input-price input {
    width: 95%;

}

.add-product-colum input[type="checkbox"] {
    width: 2%;
    margin-right: 2%;

}

.variations-row button,
.categories-products button {
    float: left !important;
    margin-top: 3%;
}

.last-col {
    background-color: transparent !important;
    margin-bottom: 10%;
}

.categories-products ul {
    padding-left: 0%;
}

.categories-products ul li {
    list-style: none;
}

.main-save-button {
    float: right;
    padding: 1.2%;
}

.error-message-product {
    float: right !important;
    padding: 1% 1%;
    margin-right: 5%;
    color: red;
    border: solid red 1px;
    border-radius: 5px;
}

.input-colom-profile {
    background-color: white;
    padding: 2%;
    border-radius: 5px;
    ;
}

.input-colom-profile input,
.input-colom-profile select {
    width: 100%;
    padding: 1%;
}

.input-colom-profile-50-50 input {
    padding: 2%;
}

.add-company-logo input[type="file"] {
    width: 75% !important;
    border: solid 1px black;
    border-radius: 5px;
}

.input-colom-profile button {
    float: right;
    padding: 2%;
}

.input-colom-profile-transparant img {
    width: 80%;
}

.input-colom-profile-50-50 select {
    width: 100%;
    padding: 2.5%;
}

.timeslots label {
    font-weight: 800;
}

.timeslots ul {
    padding-left: 0%;
}

.timeslots ul li {
    list-style: none;
    border-bottom: solid black 1px;
    padding: 3%;
    padding-bottom: 4%;
    padding-top: 4%;
}

.timeslots button {
    text-align: right;
    background-color: transparent;
    border: solid 1px red;
    float: right;
    color: red;
}

.timeslots button:hover {
    color: white;

    background-color: red;
    border: solid 1px red;
}

#main-logo {
    width: 65px;
    z-index: 999;
    margin-left: 14%;
}

.item-icon-wrapper {
    width: 20px;
    margin-right: 18px;
}

.main-navbar .navbar {
    height: 5.75rem;
}

.message-table {
    margin-bottom: 5%;
}

.message-table table {
    width: 102.5%;
    margin-left: -1.4%;

}

.message-table table tr {
    padding-top: 2%;
    padding-bottom: 2% !important;

}

.message-table .active td {
    background-color: cornflowerblue !important;
    color: white;
}

.message-table table tr th {
    width: 25%;
    padding: 2%;
    background-color: white;

}

.message-table table tr td {
    width: 20%;
    padding: 2%;
    background-color: white;

}

.button-list li {
    margin-bottom: 4%;

}

.button-list button {
    float: right !important;
    margin-top: 0%;
    border: solid 1px red;
    background-color: red;
}

.button-list button:hover {
    color: white;

    background-color: rgb(145, 7, 7);
    border: solid 1px rgb(145, 7, 7);
}

.button-onimage button {
    margin-top: 5%;
    position: absolute;
    float: right;
    margin-left: 73%;
    margin-top: 5%;
    z-index: 99;
}

.add-button {
    margin-top: 3%;
}

.login-col {
    padding: 15% 10%;
    background-color: white;
}

.add-button-settings {
    background-color: #28654B !important;
    border: #28654B 1px solid !important;
}

.add-button-settings:hover {
    background-color: #1b4432 !important;
}

.login-col img {
    width: 45%;
}

.login-col h3 {
    margin-top: 8%;
    color: black
}

.login-col p {
    margin-bottom: 3%;
}

.login-col input {
    width: 90%;
    margin-left: 3%;
    border-radius: 50px;
    border: 1px grey solid;
}

.login-col button {
    width: 50%;
    background-color: #47B362;
    padding: 2%;
    border-radius: 50px;
    margin-top: 4%;
    border: none;
    color: white;
}

.login-col button:hover {
    background-color: #3d9653;
}

.sign-on-col {
    padding: 15% 10%;
    background-color: white;
}

.sign-on-col img {
    width: 25%;
    margin-left: 37.5%;
}

.sign-on-col h3 {
    margin-top: 8%;
    color: black;
    text-align: center;
}

.sign-on-col p {
    margin-bottom: 3%;
    text-align: center;
}

.sign-on-col input {
    width: 100%;
    margin-left: 3%;
    border-radius: 50px;
    border: 1px grey solid;
}

.sign-on-col button {
    width: 50%;
    background-color: #47B362;
    padding: 2%;
    border-radius: 50px;
    margin-top: 4%;
    border: none;
    color: white;
}

.sign-on-col button:hover {
    background-color: #3d9653;
}

.login-image-bg {
    background-image: url("https://beta1.pixelpros.nl/wp-content/uploads/2020/12/82882849_2760306397383309_3835957805999718400_okopie-min.jpg");
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.ck-content {
    min-height: 200px;
}

.descript-undertitle {
    width: 100%;
    float: left;
    margin-top: 2%;
    margin-bottom: 5%;
    font-style: italic;
}

.complete-row {
    margin-top: 5%;
}

.logout-button {
    position: absolute;
    bottom: 0;
    left: 0;
    font-weight: bold;
    padding: .9375rem 1.5625rem;
    background-color: #28654B;
    width: 100%;
    color: white;
}

.logout-button:hover {
    background-color: #31815e;
    cursor: pointer;
}

.sign-on-col select {
    padding: 10px;
    margin-top: 10px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: 16px 18px;
    background-position: 98% 50%;
    cursor: auto;
    border-radius: 50px;
    width: 100%;
    margin-left: 3%;
    margin-bottom: 5%;

}

.input-check {
    width: 2% !important;
    float: left;
    margin-top: 4.5% !important;

}

.input-check-label {
    width: 93% !important;
    float: right;
    font-size: 9pt;
    font-weight: 400;
}

.sign-on-col h4 {
    font-weight: 800;
    color: black;
}

.input-check-span {
    margin-top: -3% !important;
}

.sing-on-button {
    width: 50% !important;
    margin-left: 25%;
}

.error-message {
    width: 100%;
    padding: 3%;
    border: solid red 1px;
    margin-top: 3%;
    border-radius: 5px;
    color: red;
    padding-top: 5%;
    text-align: center;
}

.add-variation {
    width: 100%;
    padding: 5% 4% !important;
    margin-top: 13% !important;
}

.impression-picture {
    width: 100%;
    margin-top: 5%;
}

.changed-row {
    background: black;
    position: fixed;
    display: inline;
    width: 85% !important;
    height: 51px !important;
    bottom: 0px !important;
    padding: 1% 1%;
    margin-left: -2%;
    z-index: 999;
}

.changed-col {}

tr {
    border-bottom: rgba(0, 0, 0, 0.24) 1px solid;
}

.changed-col p {
    color: white;
    float: left;
    width: 80%;
    margin-top: 0% !important;
}

.changed-button {
    float: right;
    width: 10%;
    margin-right: 3.5%;
    margin-top: -0.7% !important;
}

.openingstijden {
    margin-bottom: 5%;
}

.pdfviewer iframe {
    width: 100%;
    height: 800px;
}

.fill-in-form {
    width: 100%;
}

.fill-in-form input,
.fill-in-form select {
    width: 100%;
    padding: 1%;
}



.home-banner-col-items {
    text-align: center;
    padding: 15% 5% 10% 5%;
}

.home-banner-col-items h2 {
    color: white;
    line-height: 1em;
    font-family: "Flamante SemiSlab";
    font-size: 5em;
}

.home-banner-col-items h3 {
    color: white;
    margin-bottom: 8%;
    font-family: "Photograph Signature";
    font-size: 5em;
    font-weight: 300;
}

.home-banner-col-items p {
    color: white;
    font-family: "Gaspar-normal";
    font-size: 1.5em;
}

.home-banner-col-items img {
    width: 10%;
    position: absolute;
    right: 0;
}

.sign-on-button {
    width: 30%;
    background-color: #E6AA9B;
    ;
    padding: 2% 5%;
    margin-top: 2% !important;
    border: none;
    color: white;
    margin-left: 35%;
    float: left;

}


.light-button {
    width: 30%;
    background-color: #E6AA9B;
    ;
    padding: 2% 5%;
    margin-top: 2% !important;
    border: none;
    color: white;
    margin-left: 35%;
    float: left;
}

.postal-field label {
    background-color: white;
    padding: 1%;
    border: none;
}

.postal-field input {
    width: 30%;
    padding: 1%;
    border: none
}

.postal-field button {
    width: 15%;
    padding: 1%;
    border: none;
    background-color: #E6AA9B;
    margin-left: 1%;
    color: white;
    font-weight: bold;
}

.postal-field button:hover {
    background-color: #cc9486;
}

.category-col-items {
    margin-top: -4%;
}

.category-col-item {
    background-color: #C73B1F;
    padding: 5% 5% 4% 5%;
    color: white;
    border-top-left-radius: 40px;
}

.category-col-item h2 {
    color: white;
    font-family: "Flamante SemiSlab";
    font-size: 3em;
}

.category-col-item p {
    line-height: 1.4em;
    font-family: "Gaspar-normal";
    font-size: 1.2em;
}

.category-block {


    padding-right: 1%;
    position: relative;
    margin-top: -8%;

}

.sign-up-category {
    background-image: url("../../src/assets/images/streekproductengids/frontend/streekproductengids-categorie.png");
    background-color: white;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
    border-bottom-right-radius: 50px;

}

.local-cagtegory {
    background-image: url("../../src/assets/images/streekproductengids/frontend/streekproducten-bekijken-categorie.png");
    background-color: white;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
    border-bottom-right-radius: 50px;
}

.question-category {
    background-image: url("../../src/assets/images/streekproductengids/frontend/buy-products.png");

    background-color: white;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
    border-bottom-right-radius: 50px;
}

.category-link {
    position: absolute;
    bottom: 0;
    width: 50%;

    margin-left: -25% !important;
    margin-bottom: 10%;
    color: white;
    font-family: "Flamante SemiSlab";

}

.category-link:hover {
    opacity: 0.8;
    color: rgb(226, 224, 224);
    text-decoration: none;
}



.category-row div {
    height: 35em;


}

.text-category hr {
    background-color: #F7DC82;
    border: none;
    width: 60%;
    height: 4px;
    margin-left: 0%;
    margin-top: -70%;
    margin-bottom: 35%;
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
}

.text-category {
    position: absolute;
    margin-top: 90%;
    width: 70%;
    margin-left: 15%;
}

.light-button {
    width: 80%;
    margin-top: 10% !important;
    margin-left: 0%;
    padding: 4%;
    text-align: center;
    background: transparent;
    color: #838383;
    border: #838383 solid 1px;
}

.light-button:hover {
    background: #838383;
    color: white;
    border: #424141 solid 1px;
    text-decoration: none;
}

.products-row {
    background-color: #FCF7F7;
    margin-top: 5%;
    padding-top: 5%;
    padding-bottom: 5%;

}

.products-title h2 {
    font-family: "Flamante SemiSlab";
    color: #000000;
}

.products-title hr {
    background-color: #F7DC82;
    border: none;
    width: 10%;
    height: 4px;
    margin-left: 0%;
    margin-top: 0%;
    margin-bottom: 5%;
}

.category {
    padding: 1%;


}

.category-inside {
    background-color: white;
    padding: 0px !important;
    ;
    border-top-right-radius: 40px;
    float: left;
    width: 100%;
}




.category img {
    width: 30%;
    height: 80px;
    margin-left: 35%;
    margin-top: 20%;
    margin-bottom: 20%;
    background-color: white;
}

.category-meat a h3 {
    background-color: #994E37;
    width: 100%;
    padding: 8%;
    text-align: center;
    color: white;
    height: 114px;

    margin-bottom: 0px !important;
}

.category-fish a h3 {
    background-color: #61AAB8;
    width: 100%;
    padding: 8%;
    height: 114px;
    text-align: center;
    padding: 40px;
    color: white;
    margin-bottom: 0px !important;
}

.category-cheese a h3 {
    background-color: #F9E109;
    width: 100%;
    padding: 8%;
    height: 114px;
    padding-top: 15% !important;
    text-align: center;
    padding: 20px;
    color: white;
    margin-bottom: 0px !important;
}

.category-drinks a h3 {
    background-color: #656D78;
    width: 100%;
    padding: 8%;
    height: 114px;
    text-align: center;
    padding: 40px;
    color: white;
    margin-bottom: 0px !important;
}

.category-bread a h3 {
    background-color: #D77D41;
    width: 100%;
    padding: 8%;
    height: 114px;
    text-align: center;
    padding: 40px;
    color: white;
    margin-bottom: 0px !important;
}

.category-patato a h3 {
    background-color: #67A829;
    width: 100%;
    padding: 8%;
    height: 114px;
    text-align: center;
    padding: 20px;
    color: white;
    margin-bottom: 0px !important;
}


.category-dry a h3 {
    background-color: #F6AA19;
    width: 100%;
    padding: 8%;
    height: 114px;
    text-align: center;
    padding: 40px;
    color: white;
    margin-bottom: 0px !important;
}

.category-ice a h3 {
    background-color: #F59CA1;
    width: 100%;
    padding: 8%;
    height: 114px;
    text-align: center;
    padding: 40px;
    color: white;
    margin-bottom: 0px !important;
}

.highlighted-row {
    margin-top: 4%;
}

.highlighted-title h2 {
    font-family: "Flamante SemiSlab";
    color: #000000;
    text-align: center;
}

.highlighted-title hr {
    background-color: #F7DC82;
    border: none;
    width: 10%;
    height: 4px;
    margin-left: 45%;
    margin-top: 0%;
    margin-bottom: 5%;
}

.highlighted-left-image {
    background-image: url("../../src/assets/images/streekproductengids/frontend/bert-home.png");

    background-color: #929A23;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5%;
}

.highlighted-right {
    background-color: #929A23;
    padding: 8% 5% 7% 5%;
    border-bottom-right-radius: 40px;
}

.highlighted-right h2 {
    color: #FFD445;
}

.highlighted-right p {
    color: white;
}

.highlighted-right a {
    width: 30%;
    padding: 2% 4%;
    background-color: transparent;
    border: solid white 1px;
    color: white;
    position: relative;
    z-index: 999;
}

.highlighted-right a:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.267);
}

.highlighted-title-boer {
    position: absolute;
    width: 80%;
    font-family: "Flamante SemiSlab";
    color: white;
    font-size: 3em;
    bottom: 10px;
    left: 300px;
    z-index: 99;
}

.qoute-highlighted {
    position: absolute;
    opacity: 0.6;
    font-family: "Flamante SemiSlab" !important;
    z-index: 80;
    font-size: 10em;
    margin-top: -35%;
    margin-left: -25%;
}

.recipes-right {

    padding: 5%;
}

.recipes-row {
    background-image: url("../../src/assets/images/streekproductengids/frontend/recepten-wallpaper.jpg");

    background-color: #929A23;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 8%;
}

.recipes-right h2 {
    font-family: "Flamante SemiSlab";
    color: white;
    text-align: left;
}

.recipes-right hr {
    background-color: #F7DC82;
    border: none;
    width: 10%;
    height: 4px;
    margin-left: 0%;
    margin-top: 0%;
    margin-bottom: 5%;
}

.recipe-col {
    background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url("../../src/assets/images/streekproductengids/frontend/recept-dummy.jpg");
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 3%;
    height: 13em;
    padding-top: 17% !important;
    text-align: right;
    border-top-right-radius: 40px;
    color: white;

}

.recept-1 {
    background: linear-gradient(rgba(0, 0, 0, .8), rgba(0, 0, 0, .5)), url("../../src/assets/images/streekproductengids/recepten/recept-1.jpg") !important;
    background-position: center !important;
    background-size: contain !important;
}

.recept-2 {
    background: linear-gradient(rgba(0, 0, 0, .8), rgba(0, 0, 0, .5)), url("../../src/assets/images/streekproductengids/recepten/recept-2.jpg") !important;
    background-position: center !important;
    background-size: contain !important;
}

.recept-3 {
    background: linear-gradient(rgba(0, 0, 0, .8), rgba(0, 0, 0, .5)), url("../../src/assets/images/streekproductengids/recepten/recept-3.jpg") !important;
    background-position: center !important;
    background-size: contain !important;
}

.recept-4 {
    background: linear-gradient(rgba(0, 0, 0, .8), rgba(0, 0, 0, .5)), url("../../src/assets/images/streekproductengids/recepten/recept-4.jpg") !important;
    background-position: center !important;
    background-size: contain !important;
}

.recipe-col h4 {
    font-weight: bold;
    line-height: 1.3;
    color: #F7DC82;
    font-size: 11pt;

}

.border-space {
    padding-right: 1% !important;
    padding-bottom: 3%;

}

.find-row {
    margin-top: 8%;

}

.find-row h2 {
    font-family: "Flamante SemiSlab";
    color: #000000;
    text-align: center;
}

.find-row hr {
    background-color: #F7DC82;
    border: none;
    width: 10%;
    height: 4px;
    margin-left: 45%;
    margin-top: 0%;
    margin-bottom: 5%;
}

.find-title p {
    text-align: center;
}

.find-title .postal-field {
    margin-left: 12%;
    border: solid black 1px;
    padding: 2%;
}

.map {
    width: 300;
    height: 300;
}

.desktop-menu {
    position: fixed;
    z-index: 999;
    width: 100%;
    background-color: white;
    box-shadow: 0 2px 2px -2px gray;

}

.desktop-menu .logo-section img {
    width: 61%;
    margin-left: 60%;
    padding: 4%;
    margin-top: 6%;
}

.desktop-menu .sub-logo-section img {
    width: 20%;
    margin-right: 90% !important;
    padding: 4%;
    margin-top: 4%;
}


.menu-section {
    padding-top: 1.5% !important;
    padding-bottom: 1.5% !important;
    padding-left: 10% !important;
    text-align: right;

}

.phone-header {
    text-align: left !important;
    left: 0;
    position: absolute;
    top: -28px;
}

.sub-page .menu-section .active-desktop-menu {
    color: white !important;
}

.sub-page .header-section {
    margin-top: 4%;
}

.desktop-menu .menu-section {
    width: 100% !important;
    padding-left: 10%;

}

.phone-header img {
    width: 4%;
    margin-right: 2%;
    margin-top: 0%;
}

.phone-header:hover {
    border-bottom: none !important;
    opacity: 0.6;
}

.desktop-menu .menu-section a {

    padding: 1% 3%;
    color: white;
    margin-right: 1%;
    margin-top: 4%;
    font-family: 'Karla';

}

.active-desktop-menu,
.desktop-menu .menu-section a:hover {

    background-color: #EF3D34;
    color: white;

    text-decoration: none;
}

.mobile-menu {
    display: none;
}

.footer-section {
    background-color: #304B7B;

    margin-top: 5%;
    margin-left: -1%;
    width: 102%;
    border-top-left-radius: 80px;
}

.footer-section h3 {
    font-size: 24pt;
    color: white;
    padding-top: 4%;
}

.news-letter-footer h4 {
    font-size: 24pt;
    color: white;
    font-weight: 800;
    padding-top: 3%;
}

.footer-section ul li {
    list-style: none;
    padding-left: 0% !important;
}

.footer-section ul {
    padding-left: 0% !important;
    color: white;
}

.bottom-line {
    text-align: center;
    color: white;
}

.bottom-line hr {
    width: 100%;

    border-color: white;
}

.news-letter-footer-row {
    border-top-left-radius: 80px;
}

.news-letter-footer {
    text-align: center;

}

.news-letter-footer h4 {
    font-size: 18pt;
}

.news-letter-footer input {
    width: 40%;
    padding: 1% 2%;
    border-radius: 11px;
    border: none;
}

.news-letter-footer form {
    margin-bottom: 3%;
}

.footer-list {
    margin-top: 5%;
}

.footer-list ul li,
.footer-list ul li a {
    margin-bottom: 2%;
    color: white;
}

.news-letter-footer button {
    background-color: #4FAC81;
    border-radius: 11px;
    border: none;
    padding: 1% 2%;
    color: white;
}

.search-colum select {
    width: 100%;
    margin-top: 2%;
    padding: 2%
}

.opacity {
    opacity: 0.6 !important;
}

.search-button {
    background-color: #28654B;
    padding: 3% 10% !important;
    color: white;
    text-align: center;
    width: 100%;
    float: left;
    border-radius: 10px;
}


.participant-title h2 {
    font-family: "Flamante SemiSlab";
    color: #000000;
    text-align: center;
    margin-top: 12%;
}

.participant-title hr {
    background-color: #F7DC82;
    border: none;
    width: 10%;
    height: 4px;
    margin-left: 45%;
    margin-top: 0%;
    margin-bottom: 2%;
}

.participant-title p {
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
}


.particioant-col {
    padding: 1%;
}

.participant {
    border-top-left-radius: 40px !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    height: 45em;
}

.participant-content {
    padding: 8% !important;
}

.participant img {
    width: 100%;
    border-top-left-radius: 40px !important;
    height: 20em;
    object-fit: cover;

}

.participant-content i {
    margin-right: 3%;
}

.participant-content h3 {
    color: black;
    font-size: 11pt;
    margin-top: 4% !important;
}

.participant-content p {
    margin-top: 8%;

}

.participant-category {
    width: 100%;
    float: left;
    margin-top: 1%;
    margin-bottom: 4%;
}

.participant-content a {

    background-color: #929A23;

    padding: 3% 13%;
    color: white !important;

    position: relative;
    bottom: 0;
}

.filter h4 {
    text-align: center;
    color: black;
}

.filter button {
    margin-right: 2%;
    margin-bottom: 2%;
    padding: 1% 4%;
    align-items: center;
    background-color: #F0EFEF;
    color: #848484;
    border-radius: 0;
    border: none;
}

.filter button:hover {
    background-color: #C73B1F !important;
    color: white !important;
}

.active-category {
    background-color: #C73B1F !important;
    color: white !important;
}

.recepts-row {
    margin-top: 5%;
}

.recepts-left .light-button {
    width: 20%;
    background-color: transparent;
    padding: 1% 3%;
    margin-top: 2% !important;
    border: solid 1px white;
    color: white;

    float: left;
}

.recepts-left .light-button:hover {
    background-color: rgba(226, 226, 226, 0.418);
}

.recepts-left {
    background-color: #929A23;
    padding: 5%;
    margin-right: 0% !important;
}

.recepts-left h3 {
    color: #FFD445;
}

.recepts-left p {
    color: white;
}

.recepts-right {
    margin-left: -4%;
    border-bottom-right-radius: 40px !important;
}

.recepts-right img {
    border-bottom-right-radius: 40px !important;
    width: 120%;

    object-fit: contain;
}

.recepts-details-row {
    width: 104%;

    height: 25em;

    z-index: 0;
}

.recepts-details-row img {
    width: 104%;
    margin-left: -2%;
    height: 25em;

    z-index: 0;
}

.home-blog img {
    height: 35em;
}

.recepts-details-row {
    background-color: #929A23;
}

.title-recepts-detail {
    position: absolute;
    right: 15em;
    margin-top: -10%;
    background-color: #929A23;
    width: 20%;
    text-align: left;
    padding: 1% 4%;
    font-size: 14pt;
    color: white;
    font-weight: 800;
    z-index: 10;
}

.title-recepts-detail hr {
    margin-bottom: 0%;
    border: solid 1px #F7DC82;
    width: 40%;
    margin-left: 0%;
    margin-top: 4%;
    z-index: 10;
}

.left-title {
    left: 8.5em !important;
}

.recept-item-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.recept-item-image h2 {
    color: black;
}

.recept-details-container {
    padding-top: 8%;
}

.recept-details-info-cols {
    border: solid 1px #707070;
    padding: 10% 2% 5% 2%;
    text-align: center;
}

.recept-details-info-cols h4 {
    color: black;
}

.recept-item-sidebar {
    padding-left: 5%;
    position: fixed;
    right: 10%;
    width: 30%;
}

.recept-item-sidebar h5 {
    color: black;
    margin-bottom: 8%;
}

.recept-item-sidebar ul {
    padding-left: 0%;
}

.recept-item-sidebar a {
    color: black;

}

.back-button-recepts {
    border: black 1px solid;
    padding: 1.5% 8%;
    color: black;
}

.back-button-recepts:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.192);
}

.recept-item-sidebar ul li {
    list-style: none;
    padding-left: 0% !important;
    margin-bottom: 3%;
    padding-bottom: 3%;
    border-bottom: solid 1px #000000;
}

.recept-ingrdients {
    margin-top: 8%;
    padding: 2%;
    border: solid 1px #707070;

}

.recept-ingrdients h3 {
    color: black
}

.recept-ingrdients ul {
    padding-left: 0%;
}

.recept-ingrdients ul li {
    list-style: none;
    margin-bottom: 2%;
}

.recept-getting-ready {
    margin-top: 5%;
    padding: 2%;
}

.recept-getting-ready h3 {
    color: black;
}

.recept-getting-ready ul {
    padding-left: 0%;
}

.recept-getting-ready ul li {
    list-style: none;
    margin-bottom: 2%;
}

.green-title h3 {
    color: #929A23;
    font-size: 20pt;
}

.green-title h2 {
    color: #929A23;
    font-size: 24pt;
}

.participant-details-row img {
    width: 104%;
    margin-left: -2% !important;
    height: 40em;
    object-fit: cover;
    position: relative;
    z-index: 0;
}

.participant-details-container ul {
    padding-left: 0%;
}

.participant-list {
    list-style: none;
    width: 48%;
    padding-bottom: 1%;
    padding-top: 1%;
    border-bottom: black solid 1px;
    float: left;
    margin-right: 1%;
}

.participant-list-full {
    width: 97%;

}

.participant-list-full .desc-participants-list {
    text-align: left !important;
    width: 70%;
}

.participant-list .desc-participants-list input {
    width: 140%;
    margin-left: -40%;
}

.desc-participants-list select {
    width: 100%;
    padding: 1%;
}

.participant-list-full .desc-participants-list input {
    width: 100%;
    margin-left: 0%;
}


.desc-participants-list input {
    padding: 1%;
}

.participant-list img {
    width: 6%;
    margin-right: 5%;
}

.participant-list-full img {
    width: 3%;
    margin-right: 2.5%;
}

.title-participants-list {
    font-size: 10pt;
    font-weight: bold;
    margin-top: 30% !important;
    padding-top: 30% !important;

}

.desc-participants-list {
    text-align: right !important;
    float: right;
    font-size: 10pt;
}

.logo-company {
    position: absolute;
    right: 140px;
    top: 80%;
    background-color: white;
    height: 200px;
    width: 200px;
    border-radius: 500px;

}

.logo-company img {
    width: 50%;
    object-fit: contain;
    margin-top: -95%;
    margin-left: 25% !important;
}

.map div {

    z-index: 999;
    width: 80%;
}

.map-col {
    margin-top: 5%;
    margin-bottom: 40%;
    width: 80%;
    margin-left: -3%;
}

.participant-secundaire-image img {
    width: 100%;
    height: 40em;
    object-fit: contain;
}

.change-div button {
    position: fixed;
    right: 36px;
    bottom: 31px !important;
    background-color: black !important;
    border: none !important;
    padding: 1% 2% !important;
    border-radius: 50px;
    z-index: 800;
    color: white;
}

.change-div button:hover {
    opacity: 0.8;
    text-decoration: none;
}

.change-div a {
    position: fixed;
    right: 36px;
    bottom: 31px !important;
    background-color: black !important;
    border: none !important;
    padding: 1% 2% !important;
    border-radius: 50px;
    z-index: 800;
    color: white;
}

.change-div a:hover {
    opacity: 0.8;
    text-decoration: none;
}

.save-div button {
    position: fixed;
    right: 36px;
    bottom: 31px !important;
    background-color: green !important;
    border: none !important;
    padding: 1% 2% !important;
    border-radius: 50px;
    z-index: 800;
    color: white;
}

.save-div button:hover {
    opacity: 0.8;
    text-decoration: none;
}

.close-div button {
    position: fixed;
    right: 220px;
    bottom: 31px !important;
    background-color: white !important;
    border: black 1px solid !important;
    padding: 1% 2% !important;
    border-radius: 50px;
    z-index: 800;
    color: black;
}

.close-div button:hover {
    background-color: rgb(211, 209, 209) !important;
    text-decoration: none;
    color: black;
}

.overlay-row {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.651);
    z-index: 999;
    width: 103%;
    height: 100%;
    top: 0;
    padding: 10%;
}

.overlay-row-home {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.651);
    z-index: 999;
    width: 103%;
    height: 100%;
    top: 0;
    padding: 10%;
    left: 0;
}

.overlay-col-home {
    background-color: white;
    padding: 5%;
    border-radius: 5px;
}

.save-changes-home {
    margin-left: -2%;
}

.overlay-col {
    background-color: white;
    padding: 5%;
    border-radius: 5px;
}

.overlay-col form {
    margin-bottom: 4%;
}

.input-download-home input {
    width: 100%;
    margin-bottom: 2%;
    padding: 0.5%;
}

.change-logo {
    position: absolute;
    z-index: 10;
    right: 160px;
    top: 420px;
    border-radius: 50px;
    padding: 1% 1% 1% 1.2%;
    text-align: right !important;
}

.change-primaire-logo {
    position: absolute;
    z-index: 10;
    right: 160px;
    top: 120px;
    border-radius: 50px;
    padding: 1% 1% 1% 1.2%;
    text-align: right !important;
}

.close-button {
    position: absolute;
    margin-top: 5%;
    top: 10px;
    right: 66px;
    font-size: 18pt;
    color: red;
}

.close-button:hover {
    opacity: 0.5;
    cursor: pointer;
}

.loading-company {
    margin-top: 30%;
    margin-left: 24%;
}

.error-form {
    margin-top: -4% !important;
    position: absolute;
    color: red;
}

.send-form {}

.loading-primaire {
    margin-top: 20%;
    margin-left: 45%;
    margin-bottom: 15%;
}

.change-secundaire p {
    text-align: right;
    margin-right: 8%;
    font-weight: 800;
}

.color-green {
    color: green
}


.color-red {
    color: red
}

.save-changes {
    margin-left: -37% !important;
}


.save-changes input {
    width: 100%;
    margin-bottom: 2%;
}

.save-button {
    position: absolute;
    margin-top: 5%;
    bottom: 30px;
    right: 60px;
    background-color: green !important;
    border: green solid 1px;
}

.save-button:hover {
    background-color: rgba(0, 128, 0, 0.767) !important;
}

.blog-image {
    width: 40%;
    margin-left: 30%;
    margin-bottom: 5%;
}

.cta-menu {
    background-color: #C73B1F !important;
    color: white !important;
    margin-left: 10% !important;
}

.cta-menu:hover {
    opacity: 0.8;
    border-bottom: none !important;
}

.load-more button {
    width: 50% !important;
    border-radius: 0;
    margin-left: 25%;
    margin-top: 10%;
    background-color: #929A23;
    border: #929A23 solid 1px;
}

.load-more button:hover {
    opacity: 0.8;
    background-color: #929A23;
    border: #929A23 solid 1px;
}

.link-recept-home:hover {
    text-decoration: none;
    opacity: 0.5 !important;
}

.recept-kok img {
    width: 70%;
    margin-left: 25%;
}

.recept-kok p {
    text-align: left;
    margin-top: 0%;
}

.new-singip-image {
    height: 40em;
    background-color: gray;
}

.new-singip-image h2 {
    color: white;
    text-align: center;
    padding-top: 20%;
}

.new-singip-image-logo {
    height: 10em;
    background-color: #9c8f8f;
}

.new-singip-image-logo h2 {
    color: white;
    text-align: center;
    padding-top: 22%;
}


.participant a:hover {
    cursor: pointer;
}

.usp-text {
    padding: 5%;
}

.usp-text p {
    margin-left: 13%;
}

#verantwoord::before {
    background-image: url('../assets/images/jessefresh/verantwoord.png');
    background-size: 60px 60px;
    display: inline-block;
    width: 60px;
    height: 60px;
    float: left;
    margin-top: -2%;
    margin-right: 3%;

    content: "";
}

.usps-row {
    margin-bottom: 8%;
}

#vers::before {
    background-image: url('../assets/images/jessefresh/vers.png');
    background-size: 60px 60px;
    display: inline-block;
    width: 60px;
    height: 60px;
    float: left;
    margin-top: -2%;
    margin-right: 3%;

    content: "";
}

#veelzijdig::before {
    background-image: url('../assets/images/jessefresh/veelzijdig.png');
    background-size: 60px 60px;
    display: inline-block;
    width: 60px;
    height: 60px;
    float: left;
    margin-top: -2%;
    margin-right: 3%;

    content: "";
}

.usp-image {
    background-image: url("../../src/assets/images/jessefresh/Jesse.jpg");
    background-color: #cccccc;
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5%;
}

.home-page {
    padding-top: 8%;

}

.menu-col h3 {
    border-top: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    padding: 5% 5% 5% 0%;
    margin-top: 23%;
}

.menu-col ul {
    list-style: none;
    padding-left: 0%;

}

.menu-col ul li {
    padding-bottom: 23% !important;
}

.menu-title {
    width: 80%;
    float: left;
    padding-top: 2%;
    font-size: 13pt;
    font-family: 'Karla';
    font-weight: bold;
}

.menu-sub-title {
    width: 70%;
    float: left;

}

.menu-sub-title p {
    font-size: 9pt !important;
}

.price {
    width: 20%;
    float: right;
    font-size: 13pt;
    color: #ef9212;

    margin-top: -8%;

    text-align: right;
    margin-bottom: 2%;
}

.add-to-cart {
    text-align: left;

    color: #ef9212;


    float: right !important;
}


.add-to-cart-button {
    background-color: #ef9212;
    color: white;
    border: none !important;



    margin-top: -1% !important;
}

.add-to-cart-button:hover {
    background-color: rgba(255, 166, 0, 0.568);
    color: white;
    border: none !important;



    margin-top: -1% !important;

}

.menu-col img {
    width: 50%;

    margin-left: -5%;

    margin-left: 20%;
    margin-top: 15%;
}

.between-row {
    margin-top: 8%;
}

.between-col {
    background-image: url("../../src/assets/images/jessefresh/Carpaccio.jpg");
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 15%;
}

.delivery-container {
    margin-top: 5%;
}

.delivery-ul {
    padding-left: 0%;
    padding-top: 12%;
    list-style: none;

}

.logo-jesse {
    width: 80%;
    margin-top: 18% !important;
}

.delivery-li {
    width: 100%;
    float: left;
    margin-bottom: 8%;
}

.delivery-img {
    width: 30%;
    float: left;
}

.delivery-img img {
    width: 21%;
    float: left;
    margin-top: 10%;
}

.float-right {
    float: right;
}

.last-image img {
    width: 17% !important;
    float: left;
    margin-top: 7%;
}

.delivery-text {
    width: 70%;
    margin-top: 2%;
    float: left;
}

.impression-container {
    margin-top: 12%;
}

.impression-col img {
    width: 100%;
    margin-bottom: 5%;
}

.review-col {
    margin-top: 6%;
}

.review-slider {
    float: left;
    margin-top: 8%;
}

.left {
    margin-left: 5%;
}

.right {
    margin-left: 4%;
}

.google-review {
    background-color: #F5F4F5;
    width: 25%;
    padding: 3% 4% 1% 4%;
    margin-left: 3% !important;
    float: left;
    border-radius: 20px;
}

.google-review img {
    width: 20%;
    object-fit: contain;
    float: left;
}

.google-review h5 {
    float: left;
    margin-top: 2% !important;
    margin-bottom: 0% !important;
    margin-left: 0%;
}

.google-review p {
    margin-top: 17%;
    width: 100% !important;
}

.search-icon {
    width: 13% !important;
    float: right !important;
    margin-top: -2%;
    margin-right: -2%;
}

.stars {
    width: 100%;
    float: left;
    margin-left: 0%;
    margin-top: 2% !important;
}

.slider-indicator {
    margin-top: 1%;
    text-align: center;
}

.slider-indicator i {
    margin-left: 1%;
    margin-right: 1%;
}

.home-banner-row {
    background-image: url("../../src/assets/images/jessefresh/home.png");

    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 13% 5% 13% 5%;
}

.home-banner-col {
    background-color: #f2f3e3d5;
    padding: 5% 5% 5% 5%;
    border-radius: 50px;
}

.home-banner-col p {
    margin-bottom: 0% !important;
}

.house-color {
    color: #EA7F11;
}

.checkout-shoppinglist {
    padding-left: 0%;
}

.checkout-shoppinglist li {
    list-style: none;
}

.shopkeeper-image,
.product-image {
    margin-right: 1%;

}

.product-shopping-bag-image {
    float: left;
    width: 25%;
}

.product-shopping-bag-image img {
    width: 20%;
    background-color: white;
    border-radius: 14px;
    border: rgba(0, 0, 0, 0.315) 1px solid;
}

.product-checkout-image {
    float: left;
    width: 20%;
    margin-top: 5%;
}

.product-checkout-image img {
    width: 40%;
    background-color: white;
    border-radius: 14px;
    border: rgba(0, 0, 0, 0.315) 1px solid;
}

.shopkeeper-image img,
.product-image img {
    width: 20%;
    background-color: white;
    border-radius: 14px;
    border: rgba(0, 0, 0, 0.315) 1px solid;

}

.product-title-shoppinglist {
    margin-right: 4%;

    float: left;
    width: 40%;
    margin-top: 2%;
}

.product-title-shoppinglist-checkout {
    margin-right: 4%;
    margin-left: 0%;
    float: left;
    width: 40%;
    margin-top: 7%;
}


.product-amount-shoppinglist {
    margin-right: 4%;
    margin-left: 3%;
    float: left;
    width: 5%;
    margin-top: 2%;
}

.product-amount-shoppinglist-checkout {
    margin-right: 4%;
    margin-left: 3%;
    float: left;
    width: 5%;
    margin-top: 7%;
}

.product-price-shoppinglist-checkout {
    margin-right: 4%;
    margin-left: 3%;
    float: right;
    width: 8%;
    margin-top: 7%;
    text-align: right;
}

.total-shopping-bag {
    width: 100%;
    float: left;
}

.delete-price-shoppinglist {
    width: 5%;
    margin-top: 2% !important;
    float: right;
    margin-right: 1%;
    color: Red;

}

.checkout-shoppinglist li {
    margin-bottom: 2%;
    width: 100%;
    float: left;
}

.product-price-shoppinglist {
    margin-right: 4%;
    margin-left: 3%;
    float: left !important;
    width: 5%;
    margin-top: 2%;
}

.checkout-shoppinglist-total {
    float: right;
    padding-right: 4%;
}

.pay-bill-button {
    width: 20%;
    background-color: #47B362;
    border: none;
    padding: 1% 3%;
    font-weight: 800;
    color: white;
    text-align: center;
    border-radius: 5px;
    float: right;
}

.pay-bill-button:hover {
    background-color: #368d4c;
    color: white;
    text-decoration: none;
}

.pay-bill-button-checkout {
    width: 30%;
    background-color: #47B362;
    border: none;
    padding: 2% 3%;
    font-weight: 800;
    color: white;
    text-align: center;
    border-radius: 5px;
    float: right;
}

.pay-bill-button-checkout:hover {
    background-color: #368d4c;
    color: white;
    text-decoration: none;
}

.breadcrumbs-col {
    margin-top: 12%;

}

.dotted-full-line {
    margin-bottom: 5%;
    width: 72%;
}

.checkout-detail h3 {
    margin-top: 56% !important;
}

.check-box-checkout {
    width: 100% !important;
    float: left;
}

.check-box-checkout input {
    margin-right: 2%;
    margin-top: 1.8%;
}

.check-box-checkout label {
    margin-top: -2% !important;
}

.middle-choice-icon {
    margin-bottom: 4%;
}

.order-icons {
    width: 80%;
}

.order-choice input {
    margin-left: 15%;
    margin-top: 10%;
}

.middle-choice {
    margin-top: 2%;
}

.order-choice {
    float: left;
    width: 50% !important;
}

.input-full-width {
    width: 100%;
    float: left;
}

.input-half-width {
    width: 50%;
    float: left;
}

.input-full-width label,
.input-half-width label {
    margin-top: 3%;
    font-weight: 800;
}

.input-full-width input,
.input-full-width select {
    width: 95%;
    padding: 1% 3%;
}

.input-half-width input {
    width: 90%;
    padding: 2% 3%;
}

.check-payment-col {
    margin-top: 18%;
    margin-bottom: 10%;
    text-align: center;

}

.check-payment-col h1 {
    color: black;
    margin-bottom: 2%;
}

.thank-you-block {
    background-color: white;
    border-radius: 14px;
    padding: 4% 4% 1% 4%;
    margin-bottom: 3%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.firstblock-thankyou,
.secondblock-thankyou,
.thirdblock-thankyou {
    font-size: 11pt;
}

.firstblock-thankyou::before {
    display: inline-block;
    content: ' ';
    background-image: url('../assets/images/svg/frist-icon.svg');
    background-size: 40px 40px;
    height: 40px;
    width: 40px;
    padding-right: 60px;
    background-repeat: no-repeat;
    padding-top: 20px;
    vertical-align: text-top;

}

.thank-you-block p {
    margin-left: 60px;
    margin-top: -5%;
}

.secondblock-thankyou::before {
    display: inline-block;
    content: ' ';
    background-image: url('../assets/images/svg/second-icon.svg');
    background-size: 40px 40px;
    height: 40px;
    width: 40px;
    padding-right: 60px;
    background-repeat: no-repeat;
    padding-top: 20px;
    vertical-align: text-top;
}

.thirdblock-thankyou::before {
    display: inline-block;
    content: ' ';
    background-image: url('../assets/images/svg/third-icon.svg');
    background-size: 40px 40px;
    height: 40px;
    width: 40px;
    padding-right: 60px;
    background-repeat: no-repeat;
    padding-top: 20px;
    vertical-align: text-top;
}

.left-colum-thank-you img {
    width: 50%;
    margin-left: 25%;
}

.thank-you-detail {
    margin-top: 13%;
}

.thank-you-detail h2 {
    color: black;
    font-size: 22pt;

}

.right-title h2 {
    margin-bottom: 10%;
}

.thank-you-detail h4 {
    color: black;
    margin-top: -2%;
    margin-bottom: 8%;
}

.bill-detail-list {

    padding: 3% 2% 3% 2%;
    margin-left: 1%;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.bill-detail-list ul {
    list-style: none;
    padding-left: 0%;
}

.bill-detail-list ul li {
    margin-bottom: 2%;
    border-bottom: solid 1px black;
    padding-bottom: 2%;
}

.bills-component img {
    width: 100%;
}

.bills-component {
    margin-top: 2%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 3% 2% 3% 2%;
    margin-left: 1%;
    border-radius: 20px;
    background-color: white;
}

.bills-table {
    width: 100%;
}

.bill-detail-list-element {
    float: right;
}

.items-table td {
    padding-bottom: 1% !important;
    padding-top: 1% !important;
}

.afgehandeld {
    margin-top: 5%;
    margin-bottom: 20%;
}

.overlay-colum {
    background-color: white;
    border-radius: 20px !important;
    padding: 5%;
}

.overlay-colum select {
    width: 100%;
    padding: 1%;
}

.overlay-colum .add-to-cart-button {
    background-color: orange;
    color: white;
    border: none !important;

    margin-left: 0% !important;
    float: left;

    margin-top: 5% !important;
}

.menu-p {
    font-style: italic;
    margin-bottom: 4%;
    width: 100% !important;
    float: left
}

.betwen-menu {
    padding: 5%;
    height: 20px;
    width: 100%;
}

.betwen-menu-mobile {
    display: none;
}

.alert-row {}

.alert-col {
    position: fixed;
    z-index: 99;
    bottom: 0;
    text-align: center;
    width: 100% !important;
    background-color: red;
    padding: 2% 2% 0% 2%;
    width: 75% !important;
    margin-left: 12.5%;
    color: white;
    margin-bottom: 1%;
    border-radius: 20px;
}

.fa-check-circle {
    color: green;
}

.fa-times-circle {
    color: red;
}

.input-field-request {
    width: 100%;

}


.input-field-request label {
    margin-top: 5% !important;
}

.input-field-request input,
.input-field-request select {
    width: 100%;
    padding: 1.5% 2%;
    margin-top: 2%;
}

.input-field-request textarea {
    width: 100%;
    padding: 1.5% 2%;
    margin-top: 2%;
    min-height: 10rem;
}

.request-form h3 {
    margin-top: 5%;
    margin-bottom: -3%;
}

.input-field-request h5 {
    margin-top: 5%;
    margin-bottom: -2%;
}


.input-field-request label input[type="checkbox"] {
    float: left;
    width: 10%;
    margin-top: 1%;
    margin-left: -3%;
}

.right-colum-request {
    margin-top: 4% !important;
}

.request-col {
    margin-top: 5%;
}

.request-overview-col {
    background-color: white !important;
    padding: 2%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    margin-top: 5%;
    border-radius: 5px;

}

.request-overview-detail {
    margin-bottom: 3%;
    margin-top: 2%;
}

.request-overview-detail textarea {
    width: 90%;
    min-height: 150px;
}

.request-overview-detail ul {
    padding-left: 0%;
}

.request-overview-detail ul li {
    list-style: none;
    width: 100%;
    padding-bottom: 5%;
}

.left-li {
    width: 50%;
    float: left;
    font-weight: bold;

}

.right-li {
    width: 50%;
    float: left;
}

.button-col {
    margin-top: 5%;

}

.default-button-marktcommisie {
    width: 100%;
    padding: 3% 3%;
    border-radius: 50px;
    background-color: #EF3D34;
    color: white;
    border: white 1px solid;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.default-button-marktcommisie:hover {
    opacity: 0.8;
}

.request-overview-col {
    background-color: white !important;
    padding: 5%;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    margin-top: 5%;
    border-radius: 5px;
}

.voiture {
    margin-top: 9% !important;
}

.disabled-button {
    opacity: 0.7;
}

.disabled-button:hover {
    cursor: no-drop;
}

.new-table {
    border: red 2px solid !important;
    border-radius: 20px !important;
    margin-bottom: 3% !important;
}

.new-table h3 {
    width: 102% !important;
    margin-left: -1% !important;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    padding: 1% 2% !important;
    background-color: red !important;
    color: white !important;
}

.running-table {
    border: orange 2px solid !important;
    border-radius: 20px !important;
    margin-bottom: 3% !important;
}

.running-table h3 {
    width: 102% !important;
    margin-left: -1% !important;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    padding: 1% 2% !important;
    background-color: orange !important;
    color: white !important;
}

.done-table {
    border: green 2px solid !important;
    border-radius: 20px !important;
    margin-bottom: 3% !important;
}

.done-table h3 {
    width: 102% !important;
    margin-left: -1% !important;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    padding: 1% 2% !important;
    background-color: green !important;
    color: white !important;
}

.products-table table {
    width: 100% !important;
    margin-bottom: 2% !important;
    margin-left: 0%;

}

.products-table table tr {
    padding-top: 2% !important;
    padding-bottom: 2% !important;

}

.products-table .active td {
    background-color: cornflowerblue !important;
    color: white !important;
}

.products-table table tr th {
    width: 10% !important;
    padding: 1% 2% !important;
    background-color: white !important;

}

.products-table table tr td {
    width: 12% !important;
    padding: 1% 2% !important;
    background-color: white !important;

}

.details-button {
    margin-top: 3%;
    margin-right: 5%;
}

.margin-table {
    margin-top: 2%;
}

.warning {
    margin-top: 2%;


}

.warning p {
    padding: 2% 4% 2% 2% !important;
    border: solid 1px red;
    background-color: rgba(255, 0, 0, 0.627);
    border-radius: 5px;
    color: white;
}